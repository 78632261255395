<template>
  <NuxtI18nLink :to="item.url" class="banner" :class="classes" data-t="banner">
    <div class="card" data-t="banner-link" />
    <div class="label" data-t="banner-label">
      {{ item.title }}
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
const props = defineProps<{
  item: {
    url: string
    title: string | null
    publicUploadUrl: string
  }
  isMini?: boolean
}>()

const classes = computed(() => ({ mini: props.isMini }))
const backgroundImage = computed(() => `url(${props.item.publicUploadUrl})`)
</script>

<style scoped>
.card {
  display: block;

  width: 74px;
  height: 74px;

  background-color: var(--background-secondary);
  background-image: v-bind(backgroundImage);
  background-size: cover;
  border-radius: var(--border-radius-150);
}

.label {
  overflow: hidden;
  display: -webkit-box;

  max-width: 74px;

  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
  text-align: center;
  text-overflow: ellipsis;

  transition: opacity 0.2s;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.link {
  text-decoration: none;
}

.banner {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;

  text-decoration: none;

  &.mini {
    .link {
      pointer-events: none;
    }

    .label {
      max-width: 54px;
      opacity: 0.01;
    }
  }
}
</style>
