<template>
  <div class="tournament-widget" :class="{ mini: props.isMini }">
    <div class="tournament-menu-wrapper">
      <div class="tournament-menu-animation"></div>
      <div class="tournament-menu-item">
        <PrizeAmount
          :amount="compactFormat(card.totalPrizePoolAmount)"
          size="s"
        />
        <div class="badges">
          <StBadge
            class="badge-item"
            type="pedestal-solid"
            :label="format(card.prizePlaceNumber)"
            size="xs"
            icon-size="10"
          />
          <StBadge
            class="badge-item"
            type="users-solid"
            :label="format(card.membersNumber)"
            size="xs"
            icon-size="10"
          />
        </div>
      </div>
    </div>
    <p class="tournament-title">{{ card.name }}</p>
  </div>
</template>

<script setup lang="ts">
import type { Tournament } from '../../types'
import PrizeAmount from '../PrizeAmount/PrizeAmount.vue'

const props = defineProps<{
  card: Tournament
  isMini?: boolean
}>()

const { card } = toRefs(props)

const { format } = useNumberFormatter({ notation: 'compact' })
const { format: compactFormat } = useCryptoFormatter({
  notation: 'compact',
  maximumFractionDigits: 0,
  locale: 'en',
})
</script>

<style scoped>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.tournament-menu-wrapper {
  position: relative;
  z-index: 1;

  overflow: hidden;

  width: 74px;
  height: 74px;
  padding: 1px; /* stylelint-disable-line  */

  background-color: transparent;
  border-radius: var(--border-radius-150);
}

.tournament-menu-animation {
  position: absolute;
  top: -50px;
  left: -50px;

  width: 350px;
  height: 200px;

  background-image: linear-gradient(
    20deg,
    rgb(255 106 20 / 0%) 30%,
    rgb(255 106 20 / 80%),
    rgb(255 106 20 / 0%) 70%
  );

  animation: spin 8s linear infinite;
}

.tournament-menu-item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: var(--spacing-050);
  padding-top: var(--spacing-150);

  background: var(--background-primary) url('./prize-background.svg');
  background-size: cover;
  border-radius: var(--border-radius-150);
}

.badges {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  :deep(.badge-item.size-xs) {
    gap: 0;

    width: 50%;
    padding: 1px var(--spacing-025); /* stylelint-disable-line  */

    font: var(--mobile-caption-3-regular);

    border-radius: var(--border-radius-075);
  }
}

.tournament-title {
  overflow: hidden;
  display: -webkit-box;

  max-width: 74px;
  margin: 0;

  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
  text-align: center;
  text-overflow: ellipsis;

  transition: opacity 0.2s;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tournament-widget {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);

  &.mini {
    .tournament-title {
      max-width: 54px;
      opacity: 0.01;
    }
  }
}
</style>
